import React, { useState } from "react";
import classes from "./NavbarMobile.module.scss";
import Container from "../container/Container";
import Slider from "./Slider";
import logo from "../../Utilis/assets/logo";

const NavbarMobile = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [visble, setVisble] = useState<boolean>(false);

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header className={classes.headerContainer}>
      <Container className={classes.contentHeader} /*color={"#00FFFFFF"}*/>
        <div className={classes.navbar}>
          <div className={classes.navbar__logo}>
            {visble && <img src={logo} alt="logo" />}
          </div>
          <Slider scrollToSection={scrollToSection} />
        </div>
      </Container>
    </header>
  );
};

export default NavbarMobile;
