import Project from "../Interfaces/Project";
import funpunImge from "../assets/funpunImge.png";
import doggieImage from "../assets/doggieImage.png";
import funpun from "../assets/funpuntitle.png";
import doggiesitter from "../assets/diggiesittertitle.png";
const AllProjects: Project[] = [
  {
    id: 1,
    title: "Fun-Pun",
    imageTitle: funpun,
    description:
      "Fun-Pun is an exceptional English vocabulary learning system, developed as my original creation for the final project of my software engineering degree. It incorporates a unique method of language learning using creatively crafted sentences. The system includes a sophisticated pipeline with algorithmic sentence sourcing and trained models. Fun-Pun earned top honors among all nominated projects for its excellence in language learning innovation.",
    links: [
      { id: 1, link: "https://github.com/BoazBitt/FunPun" },
      { id: 2, link: "https://fun-pun.onrender.com" },
    ],
    image: funpunImge,
  },
  {
    id: 2,
    title: "Doggie Sitter",
    imageTitle: doggiesitter,
    description:
      "This software project is a convenient application for dog owners seeking dog-sitters. Additionally, it provides details on nearby dog parks and veterinary clinics. The app ensures secure payments through PayPal. With this tool, dog owners can easily find reliable care for their pets.",
    links: [{ id: 1, link: "https://github.com/BoazBitt/DoggieSitter" }],
    image: doggieImage,
  },
];

export default AllProjects;
