import React from "react";
import { ThechInterface } from "../../Utilis/Data/allTech";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import classes from "./About.module.scss";

const Image = (props: ThechInterface) => {
  const { src, link, hoverName } = props;

  return (
    <>
      <Tooltip title={hoverName} followCursor>
        <Box>
          <img
            className={classes.imgIcon}
            src={src}
            alt=""
            onClick={
              link ? () => window.open(link) : () => alert("No link just yet!")
            }
          />
        </Box>
      </Tooltip>
    </>
  );
};

export default Image;
