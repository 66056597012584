import React, { useState, ChangeEvent } from "react";
import Container from "../container/Container";
import ContactUs from "../../Utilis/Interfaces/ContactUs";
import classes from "./Contact.module.scss";
import { TextField } from "@mui/material";
import emailjs from "@emailjs/browser";
const EMAILREGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const Contact = () => {
  const [data, setData] = useState<ContactUs>({
    firstName: "",
    lastName: "",
    Email: "",
    Subject: "",
    Message: "",
  });
  const [sent, setSent] = useState<boolean>(false);
  const [err, setError] = useState<string>("");

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === err) setError("");
    setData({ ...data, [name]: value });
  };

  const validEmail = (): boolean => {
    if (data.Email) {
      return EMAILREGEX.test(data.Email);
    }
    return true;
  };

  const submitHandler = (e: any): void => {
    for (const key in data) {
      if (!data[key as keyof ContactUs]) {
        console.log(key);
        setError(key);
        return;
      }
    }
    sendEmail(e);
  };

  const sendEmail = (e: any) => {
    e.preventDefault();
    const form = document.createElement("form");
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const input = document.createElement("input");
        input.name = key;
        console.log(data[key as keyof ContactUs]);
        input.value = data[key as keyof ContactUs];
        form.appendChild(input);
      }
    }

    emailjs
      .sendForm(
        "service_m4vomck",
        "template_rcrv0wi",
        form,
        "WPjXfQMIyehDoOfXv"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSent(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Container>
      <div id="Contact" className={classes.__contact}>
        {!sent ? (
          <>
            {" "}
            <div className={classes.__title}>
              <h1>Contact Me</h1>
            </div>
            <div className={classes.__inputs}>
              <div className={classes.__nameInput}>
                <input
                  style={
                    err !== "firstName"
                      ? { color: "gray", borderBottom: "2px solid gray" }
                      : { color: "red", borderBottom: "2px solid red" }
                  }
                  placeholder="First Name"
                  name="firstName"
                  value={data.firstName}
                  onChange={onChangeHandler}
                />
                <input
                  style={
                    err !== "lastName"
                      ? { color: "gray", borderBottom: "2px solid gray" }
                      : { color: "red", borderBottom: "2px solid red" }
                  }
                  placeholder="Last Name"
                  name="lastName"
                  value={data.lastName}
                  onChange={onChangeHandler}
                />
              </div>
              <div className={classes.__subjectInput}>
                <input
                  style={
                    err !== "Subject"
                      ? { color: "gray", borderBottom: "2px solid gray" }
                      : { color: "red", borderBottom: "2px solid red" }
                  }
                  placeholder="Subject"
                  name="Subject"
                  value={data.Subject}
                  onChange={onChangeHandler}
                  maxLength={30}
                />
              </div>
              <div className={classes.__emailInput}>
                <input
                  style={
                    validEmail() && err !== "Email"
                      ? { color: "gray", borderBottom: "2px solid gray" }
                      : { color: "red", borderBottom: "2px solid red" }
                  }
                  placeholder="Email"
                  name="Email"
                  value={data.Email}
                  onChange={onChangeHandler}
                  maxLength={30}
                />
              </div>
              <div className={classes.__messgaeInput}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                        color: "white",
                        padding: "12px",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                    },
                    "& .MuiInputBase-input": {
                      color: "white",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "white",
                    },
                    width: "100%",
                  }}
                  name="Message"
                  value={data.Message}
                  onChange={onChangeHandler}
                  label="Message"
                  variant="outlined"
                  multiline
                  rows={4}
                />
              </div>
            </div>
            <div className={classes.__submit}>
              <span onClick={submitHandler}>Send</span>
            </div>
          </>
        ) : (
          <div className={classes.__sent}>
            <h1>Mail Sent We will be in touch</h1>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Contact;
