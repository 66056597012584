import socialLinks from "../Interfaces/socialLinks";
import { AiOutlineGithub } from "react-icons/ai";
import { TfiInstagram } from "react-icons/tfi";
import { SlSocialLinkedin, SlSocialFacebook } from "react-icons/sl";
import { FaTwitter } from "react-icons/fa";

const links: socialLinks[] = [
  {
    id: 1,
    name: "link",
    link: "https://www.linkedin.com/in/boaz-bitton/",
    icon: SlSocialLinkedin,
    hoverName: "Linkedin",
  },
  {
    id: 2,
    name: "git",
    link: "https://github.com/BoazBitt/",
    icon: AiOutlineGithub,
    hoverName: "Github",
  },
  {
    id: 3,
    name: "x",
    link: "https://x.com/boaz2119",
    icon: FaTwitter,
    hoverName: "X(Twitter)",
  },
  {
    id: 4,
    name: "face",
    link: "https://www.facebook.com/Boaz2119/",
    icon: SlSocialFacebook,
    hoverName: "Facebook",
  },
  {
    id: 5,
    name: "insta",
    link: "https://instagram.com/boaz2119/",
    icon: TfiInstagram,
    hoverName: "Instagram",
  },
];

export default links;

/*

*/
