import classes from './Container.module.scss';
import React, { useState, useEffect } from "react";
import ContainerProps from '../../Utilis/Interfaces/Container';



const Container = (props:ContainerProps) => {
  const [scrolled, setScrolled] = useState<boolean>(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      style={{
        backgroundColor: scrolled ? "rgba(0, 0, 0, 0.5)" : "transparent",
        backdropFilter: "blur(10px)",
      }}
    >      <div className={`${classes.content} ${props.className}`}>
        {props.children}
      </div>
    </div>
  );
};

export default Container;
