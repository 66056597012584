import MenuItem from "../Interfaces/MenuItem";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DataObjectIcon from "@mui/icons-material/DataObject";
import CodeIcon from "@mui/icons-material/Code";
import RoofingIcon from "@mui/icons-material/Roofing";
export const menuItems: MenuItem[] = [
  { id: "Home", icon: <RoofingIcon />, label: "Home" },
  { id: "About", icon: <DataObjectIcon />, label: "About" },
  { id: "Projects", icon: <CodeIcon />, label: "Projects" },
  { id: "Contact", icon: <MailOutlineIcon />, label: "Contact" },
];

export default menuItems;
