import angularjsColor from "../assets/icons/programming/angularjs-color.svg";
import cColor from "../assets/icons/programming/c-color.svg";
import cplusplusColor from "../assets/icons/programming/cplusplus-color.svg";
import csharpColor from "../assets/icons/programming/csharp-color.svg";
import css3Color from "../assets/icons/programming/css3-color.svg";
import djangoColor from "../assets/icons/programming/django-color.svg";
import expressColor from "../assets/icons/programming/express-color.svg";
import figmaColor from "../assets/icons/programming/figma-color.svg";
import firebaseColor from "../assets/icons/programming/firebase-color.svg";
import flaskColor from "../assets/icons/programming/flask-color.svg";
import graphqlColor from "../assets/icons/programming/graphql-color.svg";
import html5Color from "../assets/icons/programming/html5-color.svg";
import javaColor from "../assets/icons/programming/java-color.svg";
import javascriptColor from "../assets/icons/programming/javascript-color.svg";
import jqueryColor from "../assets/icons/programming/jquery-color.svg";
import materialuiColor from "../assets/icons/programming/materialui-color.svg";
import mongodbColor from "../assets/icons/programming/mongodb-color.svg";
import mysqlColor from "../assets/icons/programming/mysql-color.svg";
import nextjsColor from "../assets/icons/programming/nextjs-color.svg";
import nodejsColor from "../assets/icons/programming/nodejs-color.svg";
import phpColor from "../assets/icons/programming/php-color.svg";
import pythonColor from "../assets/icons/programming/python-color.svg";
import reactColor from "../assets/icons/programming/react-color.svg";
import typeormColor from "../assets/icons/programming/typeorm-color.svg";
import reduxColor from "../assets/icons/programming/redux-color.svg";
import sassColor from "../assets/icons/programming/sass-color.svg";
import sqliteColor from "../assets/icons/programming/sqlite-color.svg";
import tailwindcssColor from "../assets/icons/programming/tailwindcss-color.svg";
import typescriptColor from "../assets/icons/programming/typescript-color.svg";
import vscodeColor from "../assets/icons/ide/vscode-color.svg";
import pycharmColor from "../assets/icons/ide/pycharm-color.svg";
import eclipseColor from "../assets/icons/ide/eclipse-color.svg";

export interface ThechInterface {
  id: number;
  src: string;
  hoverName: string;
  link: string;
}

const Languages: ThechInterface[] = [
  {
    id: 1,
    src: typescriptColor,
    hoverName: "Typescript",
    link: "https://www.typescriptlang.org/docs/",
  },
  {
    id: 2,
    src: javascriptColor,
    hoverName: "Javascript",
    link: "https://devdocs.io/javascript/",
  },
  {
    id: 3,
    src: pythonColor,
    hoverName: "Python",
    link: "https://www.python.org/doc/",
  },
  {
    id: 4,
    src: javaColor,
    hoverName: "Java",
    link: "https://docs.oracle.com/en/java/",
  },
  {
    id: 5,
    src: cColor,
    hoverName: "C",
    link: "",
  },
  {
    id: 6,
    src: cplusplusColor,
    hoverName: "C++",
    link: "",
  },
  {
    id: 7,
    src: csharpColor,
    hoverName: "C#",
    link: "",
  },
  {
    id: 8,
    src: phpColor,
    hoverName: "PHP",
    link: "https://",
  },
  {
    id: 9,
    src: html5Color,
    hoverName: "HTML5",
    link: "",
  },

  {
    id: 10,
    src: css3Color,
    hoverName: "CSS",
    link: "https://developer.mozilla.org/en-US/docs/Web/CSS",
  },
];
const Frameworks: ThechInterface[] = [
  {
    id: 1,
    src: reactColor,
    hoverName: "React",
    link: "https://legacy.reactjs.org/docs/getting-started.html",
  },
  {
    id: 2,
    src: reduxColor,
    hoverName: "Redux",
    link: "https://redux.js.org/",
  },
  {
    id: 3,
    src: nextjsColor,
    hoverName: "NextJS",
    link: "https://nextjs.org/docs",
  },
  {
    id: 4,
    src: nodejsColor,
    hoverName: "NodeJS",
    link: "https://nodejs.org/en/docs",
  },
  {
    id: 5,
    src: typeormColor,
    hoverName: "TypeORM",
    link: "https://typeorm.io/",
  },
  {
    id: 6,
    src: graphqlColor,
    hoverName: "GraphQL",
    link: "https://graphql.org/learn/",
  },
  {
    id: 7,
    src: angularjsColor,
    hoverName: "Angular",
    link: "https://",
  },
  {
    id: 8,
    src: expressColor,
    hoverName: "Express",
    link: "https://expressjs.com/",
  },
  {
    id: 9,
    src: djangoColor,
    hoverName: "Django",
    link: "https://docs.djangoproject.com/en/",
  },

  {
    id: 10,
    src: jqueryColor,
    hoverName: "jQuery",
    link: "https://jquery.com/",
  },
  {
    id: 11,
    src: flaskColor,
    hoverName: "Flask",
    link: "https://",
  },
];
const Databases: ThechInterface[] = [
  {
    id: 1,
    src: mongodbColor,
    hoverName: "MongoDB",
    link: "https://www.mongodb.com/",
  },
  {
    id: 2,
    src: firebaseColor,
    hoverName: "Firebase",
    link: "https://firebase.google.com/docs",
  },

  {
    id: 3,
    src: mysqlColor,
    hoverName: "mySQL",
    link: "https://www.mysql.com/",
  },
  {
    id: 4,
    src: sqliteColor,
    hoverName: "SQL Lite",
    link: "https://",
  },
];
const IDE: ThechInterface[] = [
  {
    id: 1,
    src: vscodeColor,
    hoverName: "VSCode",
    link: "https://code.visualstudio.com/",
  },
  {
    id: 2,
    src: pycharmColor,
    hoverName: "PyCharm",
    link: "https://www.jetbrains.com/pycharm/",
  },
  {
    id: 3,
    src: eclipseColor,
    hoverName: "Eclipse",
    link: "https://eclipseide.org/",
  },
];

const Other: ThechInterface[] = [
  {
    id: 1,
    src: sassColor,
    hoverName: "Sass",
    link: "https://sass-lang.com/documentation/",
  },

  {
    id: 2,
    src: figmaColor,
    hoverName: "Figma",
    link: "https://www.figma.com/",
  },

  {
    id: 3,
    src: materialuiColor,
    hoverName: "MaterialUI",
    link: "https://mui.com/",
  },

  {
    id: 4,
    src: tailwindcssColor,
    hoverName: "TailWindCSS",
    link: "https://",
  },
];

export { Languages, Frameworks, Databases, IDE, Other };
