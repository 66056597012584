import React from "react";
import classses from "./Projects.module.scss";
import AllProjects from "../../Utilis/Data/AllProjects";
import Container from "../container/Container";
import { AiOutlineGithub } from "react-icons/ai";

const Projects = () => {
  const goTo = (link: string) => {
    return () => {
      window.open(link, "_blank");
    };
  };
  return (
    <Container>
      <div id="Projects" className={classses.__projects}>
        <div className={classses.__title}>
          <h1>Projects</h1>
        </div>
        {AllProjects.map((project) => (
          <div key={project.id} className={classses.__project}>
            <div className={classses.__projectInfo}>
              <div className={classses.__projectName}>
                <img src={project.imageTitle} alt="" />
              </div>
              <p>{project.description}</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "25px",
                }}
                className={classses.__projectLinks}
              >
                {project.links.map((link) => {
                  if (link.link.includes("github"))
                    return (
                      <div
                        key={link.id}
                        className={classses.__git}
                        onClick={goTo(link.link)}
                      >
                        <AiOutlineGithub size={70} />
                      </div>
                    );
                  return (
                    <div key={link.id} className={classses.__goLive}>
                      <span onClick={goTo(link.link)}>Go Live</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={classses.__projectImg}>
              <img src={project.image} alt="" />
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Projects;
