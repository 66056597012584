import Container from "../container/Container";
import classes from "./Navbar.module.scss";
import Social from "../social/Social";
import { Link } from "react-scroll";
import menuItems from "../../Utilis/Data/MenuItems";

const Navbar = () => {
  return (
    <header className={classes.headerContainer}>
      <Container className={classes.contentHeader}>
        <div className={classes.navbar}>
          <div className={classes.navbar__logo}></div>
          <div className={classes.navbar__links}>
            {menuItems.map((link) => {
              return (
                <Link
                  key={link.id}
                  to={link.label}
                  smooth={true}
                  duration={500}
                  offset={-100}
                  spy={true}
                >
                  <div className={classes.linkContent}>
                    {link.icon}
                    {link.label}
                  </div>
                </Link>
              );
            })}
          </div>

          <div className={classes.navbar__social}>
            <Social />
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Navbar;
