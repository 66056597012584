import React from 'react';
import MatrixRain from './MatrixRain';
import Home from '../home/Home';
const Background: React.FC = () => {
  return (
    <div style={{ position: 'relative' }}>
      <MatrixRain />
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Home/>
        
      </div>
    </div>
  );
};

export default Background;
