import React, { useEffect, useState } from "react";
import classes from "./Home.module.scss";
import Typewriter from "typewriter-effect";

const JOB = [
  "Software Engineer.",
  "Full-Stack Developer.",
];

const Home = () => {
  const [next, setNext] = useState<number>(0);
  const [visable, setVisable] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => {
      setVisable(true);
    }, 3000);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setNext((next + 1) % JOB.length);
    }, 2000);
    return () => clearTimeout(timer);
  }, [next]);
  return (
    <div id="Home" className={classes.home}>
      <div className={classes.home_info}>
        <div className={classes.__logo}></div>

      </div>
      {visable && (
        <div className={classes.__title}>
          <h1>
            <span>Boaz Bitton</span>
          </h1>
          <div className={classes.__titleData}>
            <span> I'm a </span>

            <Typewriter
              options={{
                strings: JOB,
                autoStart: true,
                loop: true,
              }}
            />
            <br />
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
