import { useState } from "react";
import classes from "./Slider.module.scss";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Divider from "@mui/material/Divider";
import Social from "../social/Social";
import MenuIcon from "@mui/icons-material/Menu";
import menuItems from "../../Utilis/Data/MenuItems";
type Anchor = "right";

interface ChildProps {
  scrollToSection: (sectionId: string) => void;
}

const SwipeableTemporaryDrawer: React.FC<ChildProps> = ({
  scrollToSection,
}) => {
  const [state, setState] = useState<{ [key in Anchor]: boolean }>({
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: 300,
        backgroundColor: "#252424",
        color: "white",
        height: "100%",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className={classes.box}>
        <div className={classes.boxContent}>
          {menuItems.map((item) => (
            <div
              key={item.id}
              className={classes.contentDiv}
              onClick={() => scrollToSection(item.id)}
            >
              {item.icon}
              <span>{item.label}</span>
            </div>
          ))}
        </div>
      </div>

      <Social />
      <Divider />
      <div className={classes.credit}>
        <h2>Boaz Bitton</h2>
        <h5>© Copyright 2023</h5>
      </div>
    </Box>
  );

  return (
    <div dir="rtl">
      <MenuIcon
        fontSize="large"
        onClick={toggleDrawer("right", true)}
        style={{ color: "white" }}
      />
      <SwipeableDrawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        {list("right")}
      </SwipeableDrawer>
    </div>
  );
};

export default SwipeableTemporaryDrawer;
