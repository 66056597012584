import React from "react";
import classes from "./About.module.scss";
import Container from "../container/Container";
import Image from "./Image";

import Grid from "@mui/material/Grid";

import {
  Languages,
  Frameworks,
  IDE,
  Databases,
  Other,
  ThechInterface,
} from "../../Utilis/Data/allTech";
import Info from "./Info";
const About = () => {
  const renderImageGrid = (title: string, items: ThechInterface[]) => {
    return (
      <>
        <Grid gap={1.5} container item spacing={1}>
          {items.map((item) => (
            <Image key={item.id} {...item} />
          ))}
        </Grid>
      </>
    );
  };

  return (
    <Container>
      <div id="About" className={classes.__about}>
        <div className={classes.__title}>
          <h1>About</h1>
        </div>
        <div className={classes.__aboutInfo}>
          <Info />
        </div>
        <div className={classes.__aboutImge}></div>
        <div className={classes.__title}>
          <h1>Skills</h1>
        </div>
        <h5>(Click on the icon for Docs)</h5>
        <div className={classes.__aboutSkills}>
          <Grid gap={1} container spacing={6}>
            {renderImageGrid("Languages", Languages)}
            {renderImageGrid("Frameworks", Frameworks)}
            {renderImageGrid("IDE", IDE)}
            {renderImageGrid("Databases", Databases)}
            {renderImageGrid("Other", Other)}
          </Grid>
        </div>
      </div>
    </Container>
  );
};

export default About;
