import React from "react";
import classes from "./Social.module.scss";
import links from "../../Utilis/Data/links";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import socialLinks from "../../Utilis/Interfaces/socialLinks";

const Social = () => {
  const navigateToLink = (name: string) => {
    const ProfileUrl = links.filter((link) => name === link.name)[0].link;
    window.open(ProfileUrl);
  };
  return (
    <div className={classes.social}>
      {links.map((socialLink: socialLinks) => (
        <Tooltip key={socialLink.id} title={socialLink.hoverName} followCursor>
          <Box>
            <div
              className={classes[socialLink.name]}
              onClick={() => navigateToLink(socialLink.name)}
            >
              <socialLink.icon size={30} />
            </div>
          </Box>
        </Tooltip>
      ))}
    </div>
  );
};

export default Social;
