import React, { useRef, useEffect } from 'react';
import classes from './MatrixRain.module.scss';

const MatrixRain = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    let animationInterval: NodeJS.Timer | number;
    let ctx: CanvasRenderingContext2D | null;

    if (canvasRef.current) {
      const canvas = canvasRef.current;
      ctx = canvas.getContext('2d');
    


      const updateCanvasSize = () => {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
   
      };

      const onResize = () => {
        updateCanvasSize();
        clearInterval(animationInterval as NodeJS.Timer);
        animationInterval = setInterval(draw, 33);
      };

      updateCanvasSize();
      window.addEventListener('resize', onResize);


      const letters = 'ABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZあいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをん';
      let fontSize = 10;
      let columns = Math.ceil(canvas.width / fontSize);
      let drops: number[] = new Array(columns).fill(1);


      const draw = () => {
        if (!ctx) return; 
        ctx.fillStyle = 'rgba(0, 0, 0, .1)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        for (let i = 0; i < drops.length; i++) {
          const text = letters[Math.floor(Math.random() * letters.length)];
          ctx.fillStyle = '#0f0';
          ctx.fillText(text, i * fontSize, drops[i] * fontSize);
          drops[i]++;
          if (drops[i] * fontSize > canvas.height && Math.random() > .95) {
            drops[i] = 0;
          }
        }

      };

      animationInterval = setInterval(draw, 30) as NodeJS.Timer;
      
      
      return () => {
        clearInterval(animationInterval as NodeJS.Timer);
        window.removeEventListener('resize', onResize);
        
        

      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <canvas className={classes.__mask}  ref={canvasRef} style={{ display: 'block', background: '#000', margin: 0, padding: 0 }} />
  );
};

export default MatrixRain;
