import React from "react";

const Info = () => {
  return (
    <p>
      I'm Boaz, a <span>Software Engineer</span> graduated in 2023.
      <br />
      As a Full-Stack Web Developer, I specialize in crafting both the front-end
      and back-end components of websites and web applications. I invite you to
      explore some of my showcased work in the Projects section.
      <br />
      <br />
      I am actively seeking job opportunities where I can make meaningful
      contributions, expand my knowledge, and further develop my skills.
      <br />
      <br />
      Some of my <span>Hobbies</span> on my spare time are reading,playing video
      games and watching anime.
      <br />
      <br />
      If you have an exciting opportunity that matches my skills and experience,
      or if you're simply looking for a geeky friend, I'm interested! plaese
      feel free the contact me in the Contact section.
    </p>
  );
};

export default Info;
