import React, { useState, useEffect } from "react";
import "./App.css";
import Navbar from "./components/navbar/Navbar";
import NavbarMobile from "./components/navbar/NavbarMobile";
import About from "./components/about/About";
import Projects from "./components/projects/Projects";
import Contact from "./components/contact/Contact";
import Background from "./components/matrixrain/Background";

const App = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth > 820);

  const handleResize = () => {
    setScreenWidth(window.innerWidth > 820);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {screenWidth ? <Navbar /> : <NavbarMobile />}
      <Background />
      <About />
      <Projects />
      <Contact />
    </div>
  );
};

export default App;
